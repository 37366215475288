import React from 'react';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaGithub } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";


const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href="https://twitter.com/the_techie_boss">
        <BsTwitter /> 
      </a>  
    </div>
    <div>
      <a href="https://www.linkedin.com/in/humphreydev">
         <FaLinkedinIn /> 
      </a>  
    </div>
    <div>
      <a href="https://github.com/humphreydev5">
        <FaGithub />
      </a>
    </div>
    <div>
      <a href="https://instagram.com/the_techie_boss">
      <BsInstagram />
      </a>
    </div>
  </div>
);

export default SocialMedia;